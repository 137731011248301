<template>
  <div class="classesBegin" @click.stop="classesBegin">
    <slot></slot>
    <!--使用直播助手上课-->
    <!-- <div @click.stop v-if="$parent.downLoadWindowsId === schedule_id" class="down-load-pc-windows">
      <div class="title">请使用直播助手上课</div>
      <div class="down-load" @click="downLoad">点此下载安装</div>
      <i class="close" @click="closeDownLoadPc"></i>
    </div> -->
    <div v-if="steamAppConfrim" @click.stop>
      <el-dialog
        :visible.sync="steamAppConfrim_show"
        width="800px"
        height="518px"
        :top="$store.state.isWindowsAppV2 ? '4vh' : '15vh'"
        @closed="dialog_closed"
      >
        <el-tabs v-model="activeMenu">
          <el-tab-pane label="直播助手进入" name="ddkkApp">
            <div class="app-content">
              <div class="left">
                <img class="logo" src="~@ass/img/1.3.5/icon_zbzs.png" alt="" />
                <div class="name">叮叮开课直播助手</div>
                <div v-if="!isWindowsApp" class="down-load" @click="downLoad">
                  下载<span style="font-size: 12px;padding-left: 10px;">{{ userInfo.pcClientVersion }}</span>
                </div>
              </div>
              <div class="right">
                <div class="detail">
                  <div class="text">
                    叮叮开课直播助手是讲师在PC电脑端进行直播推流的工具，支持windows7及以上系统，未安装请先下载安装后打开进入教室；已安装请在直播助手进入教室
                  </div>
                  <div class="tutorials">
                    <div
                      class="button"
                      @click="
                        open(
                          'https://www.yuque.com/docs/share/e5309574-d4cd-47ac-8264-d30131d45a07?#'
                        )
                      "
                    >
                      查看教程
                    </div>
                  </div>
                </div>
                <div class="join">
                  <button
                    v-if="isWindowsApp"
                    class="join-btn"
                    @click="joinClassRoom"
                  >
                    进入教室
                  </button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="obsPushStream" label="OBS进入" name="obs">
            <div class="app-content">
              <div class="left">
                <img class="logo" src="~@ass/img/1.3.5/icon_obstl.png" alt="" />
                <div class="name">OBS推流工具</div>
                <div
                  class="down-load"
                  @click="chatrRoom"
                  v-if="type != 4 && !isWindowsApp"
                  style="bottom: 93px"
                >
                  打开聊天室
                </div>
                <div
                  class="down-load"
                  @click="open(userInfo.obsClientDownload)"
                >
                  下载
                </div>
              </div>
              <div class="right">
                <div class="detail">
                  <div class="text">
                    OBS推流工具是第三方直播软件，非叮叮开课官方所有，支持Windows
                    系统和Mac
                    系统，无需安装叮叮开课直播助手，详细操作请查看教程！
                  </div>
                  <div class="tutorials" style="text-align: left">
                    <div
                      class="button"
                      @click="
                        open(
                          'https://www.yuque.com/docs/share/f568ec57-9ea3-4ef7-b07a-d871f6a74fb7?#'
                        )
                      "
                    >
                      查看教程
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="steram-contaim">
                    <div class="title">服务器</div>
                    <div class="steram">
                      <div class="url">{{ sterameInfo.pushUrl }}</div>
                      <button
                        class="copy for-copy"
                        :data-clipboard-text="sterameInfo.pushUrl"
                      >
                        复制
                      </button>
                    </div>
                  </div>
                  <div class="steram-contaim">
                    <div class="title">
                      串流密钥
                      <span class="red">
                        （过期时间：{{
                          sterameInfo.failure_time
                            | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
                        }}）
                      </span>
                    </div>
                    <div class="steram">
                      <div class="url">{{ sterameInfo.pushKey }}</div>
                      <button
                        class="copy"
                        :data-clipboard-text="sterameInfo.pushKey"
                      >
                        复制
                      </button>
                    </div>
                  </div>
                  <div
                    class="regenerate"
                    @click="
                      type == 4
                        ? opencoursegetSteramUrl(true)
                        : getSteramUrl(true)
                    "
                  >
                    {{ regenerateLoading ? '生成中...' : '重新生成' }}
                  </div>
                  <div class="tips">
                    提示：推流地址可自行使用第三方推流工具进行直播，复制对应的地址在OBS软件粘贴即可，如提示已失效，请点击重新生成
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ClipboardJS from 'clipboard'

export default {
  name: 'classesBegin',

  props: {
    //3,直播课 4,公开课
    type: {
      type: [String,Number],
      default: undefined,
    },
    item: Object,
  },

  data() {
    return {
      activeMenu: 'ddkkApp',
      steamAppConfrim: false,
      steamAppConfrim_show: false,

      sterameInfo: {},

      regenerateLoading: false,
    }
  },

  created() {},

  watch: {
    // 复制
    activeMenu: {
      handler(val) {
        if (val == 'obs') {
          // 复制推流地址
          this.$nextTick(() => {
            if (this.clipboardJS) {
              this.clipboardJS.destroy()
            }

            this.clipboardJS = new ClipboardJS(
              document.getElementsByClassName('copy')
            ).on('success', () => {
              this.$root.prompt({
                msg: '复制成功',
                type: 'success',
              })
            })
          })
        }
      },
      immediate: false,
    },
  },

  computed: {
    ...mapState(['isWindowsApp', 'userInfo']),
    ...mapGetters(['isJiGouAdmin', 'isSpeedLive', 'obsPushStream']),

    // 获取章节ID
    schedule_id() {
      return (
        this.item.schedule_id ||
        this.item.correlation_id ||
        this.item.open_class_id
      )
    },

    // 获取课程类型
    course_type() {
      return this.item.course_type || this.item.type
    },

    // 推流助手App选择
    steamApps() {
      const menu = [{ name: '直播助手进入', id: 'ddkkApp' }]
      if (this.obsPushStream) {
        menu.push({ name: 'OBS进入', id: 'obs' })
      }
      return menu
    },
  },

  methods: {
    dialog_closed() {
      this.steamAppConfrim = false
      this.activeMenu = 'ddkkApp'
    },

    // 开始上课
    classesBegin() {
      // 小班课跳浏览器
      if (this.course_type == 2) {
        // 剩余的叮豆<当前课堂的时长*设置的人数*单价0.5
        const consumption =
          Math.floor(this.item.classHour * this.item.order_num * 30 * 100) / 100

        // 叮豆不足 无法上课
        if (this.userInfo.t_amount < consumption) {
          const redText = (v) => `<i style="color:#ff3535;">${v}</i>`
          const html = `<p>该章节上课时长:${redText(
            this.item.classHour
          )}小时，上课人数:${redText(
            '1v' + this.item.order_num
          )}，需消耗${redText(consumption)}个叮豆，当前剩余${redText(
            this.userInfo.t_amount
          )}个叮豆，无法进入教室，请先充值！</p>`

          return this.$confirm(html, '叮豆不足！', {
            confirmButtonText: '去充值',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
          }).then(() => {
            if (this.isJiGouAdmin) {
              this.$router.push('/capitalAccount/TopUpDingDou')
            } else {
              this.$root.prompt({
                msg: '请联系管理员充值',
              })
            }
          })
        } else {
          this.$store.dispatch(
            'open',
            `${location.origin}/live/privateClassLive#/?correlation_id=${this.schedule_id}`
          )
        }
      } else if (this.userInfo.t_amount <= 0) {
        this.$confirm(
          `<p>当前剩余<i style="color:#ff3535;">${this.userInfo.t_amount}</i>个叮豆，无法进入教室，请先充值！建议保证叮豆数量充足以免影响讲师和学员的授课学习</p>`,
          '叮豆不足！',
          {
            confirmButtonText: '去充值',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
          }
        ).then(() => {
          this.$router.push({
            path: '/capitalAccount/TopUpDingDou',
          })
        })
      } else if (this.item.live_mode == 2 && this.type != 4) {
        this.$store.dispatch(
          'open',
          `${location.origin}/live/speedLive#/?correlation_id=${this.schedule_id}`
        )
      } else if (this.userInfo.leftStorage <= 0) {
        // 如果是客户端 且存储空间达到上限 大班课就提示空间已满
        this.$confirm('资料库存储空间已满，无法上课，请联系客服', '温馨提示', {
          confirmButtonText: '我知道了',
          showCancelButton: false,
        })
      } else {
        let post =
          this.type == 4
            ? { open_class_id: this.item.open_class_id }
            : { correlation_id: this.schedule_id }
        this.$http({
          url: '/Assistant/checkTeacher',
          data: post,
          callback: () => {
            if (!this.isWindowsApp) {
              // 否则下载客户端
              this.steamAppConfrim = true
              this.$nextTick(() => {
                this.steamAppConfrim_show = true
              })
              // 如果开启了OBS推流就去 获取数据
              if (this.type == 4) {
                this.opencoursegetSteramUrl()
              } else {
                this.getSteramUrl()
              }
            } else {
              this.joinClassRoom()
            }
          },
        })
      }
    },

    // 进入教室
    joinClassRoom() {
      let schedule_id = ''
      let type = ''
      if(this.item.schedule_live_mode){
        // type = prompt("进入教室的直播课类型,公开课type为6,普通直播为0,schedule_live_mode:"+this.item.schedule_live_mode+',open_class_id:'+this.item.open_class_id);
        type = this.item.schedule_live_mode == 3 ? 6 : 0
        if(this.item.schedule_live_mode == 3){
          schedule_id = this.item.open_class_id
          delete this.item.correlation_id
        } else {
          schedule_id = this.item.correlation_id
          delete this.item.open_class_id
        }
        this.item.live_mode = this.item.schedule_live_mode
      } else {
        schedule_id = this.schedule_id
        type = this.item.open_class_id ? 6 : 0;
      }
      // console.log({
      //   schedule_id,
      //   uid: this.userInfo.uid,
      //   // 公开课type 为6
      //   // type: this.item.schedule_live_mode?this.item.schedule_live_mode == 3 ? 6 : 0 : this.item.open_class_id ? 6 : 0,
      //   // type: this.item.schedule_live_mode?0 : this.item.open_class_id ? 6 : 0,
      //   type,
      //   course: this.item,
      // });
      // 大班课直接上课
      try {
        this.$store.dispatch('sendToWindowsMsg', {
          schedule_id,
          uid: this.userInfo.uid,
          // 公开课type 为6
          // type: this.item.schedule_live_mode?this.item.schedule_live_mode == 3 ? 6 : 0 : this.item.open_class_id ? 6 : 0,
          // type: this.item.schedule_live_mode?0 : this.item.open_class_id ? 6 : 0,
          type,
          course: this.item,
        })
      } catch (e) {
        this.$confirm(JSON.stringify(e), '进入教室失败', {
          confirmButtonText: '我知道了',
          showCancelButton: false,
        })
      }
    },

    // 下载客户端
    downLoad() {
      this.$store.dispatch('downLoad', this.userInfo.pcClientDownload)
    },

    // 获取推流地址
    // regenerate 重新生成
    getSteramUrl(regenerate) {
      this.regenerateLoading = true
      let option
      if (regenerate) {
        option = {
          url: '/course/getRoomUpdatePushUrl',
          data: { correlation_id: this.schedule_id },
        }
      } else {
        option = {
          url: `/course/getRoomUpdatePushUrl?correlation_id=${this.schedule_id}`,
        }
      }

      this.$http(
        _.assign({}, option, {
          callback: ({ data }) => {
            this.sterameInfo = data
          },
          complete: () => {
            this.regenerateLoading = false
          },
        })
      )
    },

    //公开课推流地址
    opencoursegetSteramUrl(regenerate) {
      this.regenerateLoading = true
      let option
      if (regenerate) {
        option = {
          url: '/openClass/getRoomUpdatePushUrl',
          data: { open_class_id: this.item.open_class_id },
        }
      } else {
        option = {
          url: `/openClass/getRoomUpdatePushUrl?open_class_id=${this.item.open_class_id}`,
        }
      }

      this.$http(
        _.assign({}, option, {
          callback: ({ data }) => {
            this.sterameInfo = data
          },
          complete: () => {
            this.regenerateLoading = false
          },
        })
      )
    },

    // 打开外部链接
    open(url) {
      this.$store.dispatch('open', url)
    },

    // 进入聊天室
    chatrRoom() {
      if (this.$root.window) {
        this.$root.window.close()
      }

      this.$root.window = window.open(
        `/#/charRoom?correlation_id=${
          this.schedule_id
        }&chatrRoom=${Math.random()}`,
        'chatRoom',
        `width=430,height=620,left=${
          window.screen.availWidth - 530
        },top=300,location=no,menubar=no,scrollbars=no,resizable=no,toolbar=no`
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.classesBegin {
  position: relative;
  /**直播助手上课**/
  .down-load-pc-windows {
    position: absolute;
    right: 0;
    top: -90px;
    z-index: 2;
    width: 206px;
    height: 96px;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 19px 0 0 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 19px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(240, 240, 240, 1);
    .title {
      font-size: 13px;
      line-height: 17px;
      word-break: break-all;
      color: rgba(0, 0, 0, 1);
    }
    .down-load {
      cursor: pointer;
      font-size: 12px;
      margin-top: 23px;
      line-height: 16px;
      color: rgba(27, 157, 151, 1);
      &:hover {
        text-decoration: underline;
      }
    }
    .close {
      width: 34px;
      height: 34px;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      background: url('../../assets/img/ico_gbts.png') no-repeat center center;
      background-size: 12px;
    }
  }
}
::v-deep .el-dialog__headerbtn {
  z-index: 3;
}
::v-deep .el-dialog__body {
  margin-top: -50px;
  cursor: auto;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #ededed;
}
::v-deep .el-tabs__active-bar {
  height: 1px;
}
.app-content {
  display: flex;
  padding: 42px 0 56px 0;
  .left {
    width: 180px;
    height: 360px;
    background: #f6f6f6;
    position: relative;
    .logo {
      width: 64px;
      height: 64px;
      margin: 49px auto 0;
    }
    .name {
      font-size: 14px;
      color: #333333;
      margin-top: 20px;
      text-align: center;
      margin-right: 0;
      border-radius: 0;
      display: block;
      line-height: 21px;
    }
    .down-load {
      width: 126px;
      border-radius: 4px;
      font-size: 14px;
      color: $color;
      line-height: 42px;
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 50%;
      box-sizing: border-box;
      transform: translateX(-50%);
      border: 1px solid $color;
      transition: background 0.25s;
      &:hover {
        color: #fff;
        background: $color;
        cursor: pointer;
        border: 1px solid $color;
      }
    }
  }
  .right {
    flex: 1;
    margin-left: 46px;
    position: relative;
    overflow: hidden;
    .detail {
      .text {
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .tutorials {
        font-size: 0;
        text-align: right;
        .button {
          font-size: 14px;
          color: #1b9d97;
          line-height: 14px;
          margin-top: 20px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
    .join {
      font-size: 0;
      text-align: right;
      margin-top: 257px;
      .join-btn {
        border: 0;
        display: inline-block;
        width: 150px;
        height: 42px;
        background: #0aa29b;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .bottom {
      margin-top: 40px;
      .steram-contaim {
        margin-bottom: 20px;
        .title {
          font-size: 14px;
          color: #333333;
          line-height: 19px;
        }
        .steram {
          display: flex;
          margin-top: 10px;
          .url {
            flex: 1;
            height: 42px;
            border-radius: 4px;
            font-size: 12px;
            color: #666666;
            line-height: 42px;
            padding: 0 11px;
            background: #f8f8f8;
            border: 1px solid #dddddd;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .copy {
            border: 0;
            width: 80px;
            height: 44px;
            font-size: 14px;
            color: #ffffff;
            line-height: 42px;
            text-align: center;
            cursor: pointer;
            background: #1b9d97;
            border-radius: 0px 4px 4px 0px;
            border: 1px solid #dddddd;
            margin-top: -1px;
          }
        }
      }
      .regenerate {
        font-size: 14px;
        font-weight: bold;
        color: #1b9d97;
        line-height: 19px;
        cursor: pointer;
      }
      .tips {
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        margin-top: 29px;
      }
    }
  }
}
</style>
