var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classesBegin",on:{"click":function($event){$event.stopPropagation();return _vm.classesBegin.apply(null, arguments)}}},[_vm._t("default"),(_vm.steamAppConfrim)?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-dialog',{attrs:{"visible":_vm.steamAppConfrim_show,"width":"800px","height":"518px","top":_vm.$store.state.isWindowsAppV2 ? '4vh' : '15vh'},on:{"update:visible":function($event){_vm.steamAppConfrim_show=$event},"closed":_vm.dialog_closed}},[_c('el-tabs',{model:{value:(_vm.activeMenu),callback:function ($$v) {_vm.activeMenu=$$v},expression:"activeMenu"}},[_c('el-tab-pane',{attrs:{"label":"直播助手进入","name":"ddkkApp"}},[_c('div',{staticClass:"app-content"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"logo",attrs:{"src":require("@ass/img/1.3.5/icon_zbzs.png"),"alt":""}}),_c('div',{staticClass:"name"},[_vm._v("叮叮开课直播助手")]),(!_vm.isWindowsApp)?_c('div',{staticClass:"down-load",on:{"click":_vm.downLoad}},[_vm._v(" 下载"),_c('span',{staticStyle:{"font-size":"12px","padding-left":"10px"}},[_vm._v(_vm._s(_vm.userInfo.pcClientVersion))])]):_vm._e()]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v(" 叮叮开课直播助手是讲师在PC电脑端进行直播推流的工具，支持windows7及以上系统，未安装请先下载安装后打开进入教室；已安装请在直播助手进入教室 ")]),_c('div',{staticClass:"tutorials"},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.open(
                        'https://www.yuque.com/docs/share/e5309574-d4cd-47ac-8264-d30131d45a07?#'
                      )}}},[_vm._v(" 查看教程 ")])])]),_c('div',{staticClass:"join"},[(_vm.isWindowsApp)?_c('button',{staticClass:"join-btn",on:{"click":_vm.joinClassRoom}},[_vm._v(" 进入教室 ")]):_vm._e()])])])]),(_vm.obsPushStream)?_c('el-tab-pane',{attrs:{"label":"OBS进入","name":"obs"}},[_c('div',{staticClass:"app-content"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"logo",attrs:{"src":require("@ass/img/1.3.5/icon_obstl.png"),"alt":""}}),_c('div',{staticClass:"name"},[_vm._v("OBS推流工具")]),(_vm.type != 4 && !_vm.isWindowsApp)?_c('div',{staticClass:"down-load",staticStyle:{"bottom":"93px"},on:{"click":_vm.chatrRoom}},[_vm._v(" 打开聊天室 ")]):_vm._e(),_c('div',{staticClass:"down-load",on:{"click":function($event){return _vm.open(_vm.userInfo.obsClientDownload)}}},[_vm._v(" 下载 ")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v(" OBS推流工具是第三方直播软件，非叮叮开课官方所有，支持Windows 系统和Mac 系统，无需安装叮叮开课直播助手，详细操作请查看教程！ ")]),_c('div',{staticClass:"tutorials",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.open(
                        'https://www.yuque.com/docs/share/f568ec57-9ea3-4ef7-b07a-d871f6a74fb7?#'
                      )}}},[_vm._v(" 查看教程 ")])])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"steram-contaim"},[_c('div',{staticClass:"title"},[_vm._v("服务器")]),_c('div',{staticClass:"steram"},[_c('div',{staticClass:"url"},[_vm._v(_vm._s(_vm.sterameInfo.pushUrl))]),_c('button',{staticClass:"copy for-copy",attrs:{"data-clipboard-text":_vm.sterameInfo.pushUrl}},[_vm._v(" 复制 ")])])]),_c('div',{staticClass:"steram-contaim"},[_c('div',{staticClass:"title"},[_vm._v(" 串流密钥 "),_c('span',{staticClass:"red"},[_vm._v(" （过期时间："+_vm._s(_vm._f("formatTimeStamp")(_vm.sterameInfo.failure_time,'yyyy-MM-dd hh:mm:ss'))+"） ")])]),_c('div',{staticClass:"steram"},[_c('div',{staticClass:"url"},[_vm._v(_vm._s(_vm.sterameInfo.pushKey))]),_c('button',{staticClass:"copy",attrs:{"data-clipboard-text":_vm.sterameInfo.pushKey}},[_vm._v(" 复制 ")])])]),_c('div',{staticClass:"regenerate",on:{"click":function($event){_vm.type == 4
                      ? _vm.opencoursegetSteramUrl(true)
                      : _vm.getSteramUrl(true)}}},[_vm._v(" "+_vm._s(_vm.regenerateLoading ? '生成中...' : '重新生成')+" ")]),_c('div',{staticClass:"tips"},[_vm._v(" 提示：推流地址可自行使用第三方推流工具进行直播，复制对应的地址在OBS软件粘贴即可，如提示已失效，请点击重新生成 ")])])])])]):_vm._e()],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }